// QHP-Specific Styles
.qhp-logo-cms>svg {
    width: 7rem;
    margin-right: 2rem;

    @include at-media($theme-header-min-width) {
        width: 14rem;
    }
}

.qhp-logo-hhs>svg {
    width: 4rem;
    height: 4rem;
    margin-right: 1rem;

    image {
        width: 4rem;
        height: 4rem;
    }
}

.qhp-site-subheading {
    margin-bottom: 0;
    clear: both;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 1rem;
    color: rgba(0, 0, 0, .6);
    letter-spacing: .025em;
    margin-bottom: .5rem;
    margin-top: 0;

    &+h1 {
        margin-top: 0;
    }
}

.qhp-footer-hhs {
    @include u-font('body', '2xs');
    max-width: 22rem;
}

.qhp-section--light {
    background-color: color("primary-lighter");
}

.qhp-callout {
    background-color: color("primary-lighter");
    @include u-padding(4);
    @include u-margin-top(4);
    @include u-radius('lg');

    @include at-media($theme-header-min-width) {
        @include u-margin-top(0);
    }
}

.qhp-prose--full,
.qhp-prose--full>p,
.qhp-prose--full>ul,
.qhp-prose--full>ul li {
    max-width: 100%;
}

.qhp-list {
    @extend %usa-list;

    li {
        @extend %usa-list-item;

    }

    &.qhp-list--hover {
        @include u-margin-x(0);
        @include u-padding(0);

        li {
            position: relative;
            list-style-type: none;
            @include u-padding-left(3);
            @include u-padding-right(1);

            &:before {
                position: absolute;
                left: 0.5rem;
                content: "\2022";
                font-size: 1.625rem;
                line-height: 0.75;
            }

            &:hover {
                background-color: color('gray-5');
                @include u-radius('md');
            }

        }
    }

    &--buttons {

        a,
        button {
            text-align: left;
        }
    }
}

@include override-prose {
    .qhp-list--unstyled {
        list-style-type: none !important;
        padding-left: 0;

        li {
            list-style-type: none !important;
        }

    }
}

.qhp-card-vendor {

    h3,
    h4 {
        margin-top: 0;
        margin-bottom: 0;
        color: color('blue-warm-70');
    }

    .qhp-card-icon {
        position: absolute;
        top: -0.5rem;
        right: -0.5rem;

        &--approved {
            color: color('green-cool-40');
        }

        &--conditional {
            color: color('orange-warm-40')
        }

        svg {
            background-color: color('white');
            border-radius: 100%;
        }
    }
}

/**
 * Custom animated spinner based off of spinner from CMS Design System
 * https://design.cms.gov/components/spinner/
 */
.qhp-spinner {
    height: 2rem;
    width: 2rem;
    box-sizing: border-box;
    display: inline-block;
    position: relative;

    &::before,
    &::after {
        border-width: 0.25rem;
        height: 2rem;
        left: 0;
        top: 0;
        width: 2rem;
    }

    &::before,
    &::after {
        border-radius: 100%;
        border-style: solid;
        box-sizing: border-box;
        content: '';
        display: block;
        left: 0;
        position: absolute;
        top: 0;
    }

    &::before {
        border-color: inherit;
        opacity: 0.2;
    }

    &::after {
        animation: spin 0.8s infinite linear;
        border-bottom-color: transparent;
        border-left-color: inherit;
        border-right-color: transparent;
        border-top-color: transparent;
        transform: translateZ(0);

        @media (-ms-high-contrast: active),
        (forced-colors: active) {
            border-color: WindowText;
            border-left-color: Window;
        }
    }

    &--small {
        height: 1rem;
        width: 1rem;

        &::before,
        &::after {
            border-width: 0.125em;
            height: 1rem;
            left: 0;
            top: 0;
            width: 1rem;
        }
    }

    &--big {
        height: 2.5rem;
        width: 2.5rem;

        &::before,
        &::after {
            border-width: 0.3125em;
            height: 2.5rem;
            left: 0;
            top: 0;
            width: 2.5rem;
        }
    }

    .usa-button>& {
        fill: currentColor;
        height: 1em;
        margin-bottom: -0.1em; // apply negative margin so icon doesn't affect height
        margin-top: -0.1em;
        position: relative;
        top: -0.1em;
        vertical-align: middle;
        width: 1em;

        &::before,
        &::after {
            border-width: 0.125em;
            height: 1em;
            left: 0;
            top: 0;
            width: 1em;
        }

        @media (-ms-high-contrast: active),
        (forced-colors: active) {
            fill: ButtonText;
        }
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.datatable-header-text-wrap {
    white-space: pre-line !important;
    word-wrap: break-word;
}

.filter-text {
    @extend %block-input-general;
    @extend %block-input-styles;
}

.opacity-95 {
    opacity: 0.95;
}

// USWDS Overrides and Extensions
.usa-logo {
    a {
        color: $qhp-color-primary;
        font-weight: font-weight("normal");
    }

    &__text {
        color: $qhp-color-primary;
        font-weight: font-weight("normal");
    }
}

.usa-header {
    +.usa-hero {
        @include at-media($theme-header-min-width) {
            border-top: none;
        }
    }

    +.usa-section,
    +main {
        @include at-media($theme-header-min-width) {
            border-top: none;
        }
    }
}

.usa-hero {
    @include u-padding-top(6);
    @include u-padding-bottom(6)
}

.usa-header--extended {

    @include at-media($theme-header-min-width) {
        .usa-nav {
            @include u-text('white');
            border-top: none;
            background-color: $qhp-color-primary;
            z-index: 1000;

            &__secondary {
                @include u-bg('white')
            }
        }

        .usa-nav__primary-item {
            >a {
                color: color('white');
            }

            >.usa-current {
                color: color('white');
                background-color: color('primary');
            }

            >.usa-nav__link:hover {
                color: $qhp-color-primary;
                background-color: color('blue-20');
            }

            // Don't show extended border
            .usa-nav__link::after,
            .usa-nav__link:hover::after {
                display: none;
            }
        }
    }

    .usa-logo {
        @include at-media($theme-header-min-width) {
            font-size: 2rem;
            margin: units(4) 0 units(4);
        }
    }
}

.usa-button {
    $b: &;

    &--small {
        border-radius: radius($theme-button-border-radius);
        font-size: font-size($theme-button-font-family, "2xs");
        padding: units(1) units(2);

        &#{$b}--icon {
            padding-top: 0.3125rem;
        }
    }

    &--block {
        width: 100%;
    }

    &--icon {
        @include u-padding-top(1);
    }
}

.usa-icon {
    position: relative;
    top: 0.125em;

    &--size-3 {
        top: 0;
    }

    &--button {
        top: 0.1em;
        width: 0.75em;
        height: 0.75em;
    }
}

.usa-nav__secondary {
    @include at-media($theme-header-min-width) {
        bottom: 6.25rem;
    }
}

.usa-prose {
    h1+.usa-card-group {
        @include u-margin-top(2)
    }

    &--wide {

        >p,
        >ul li {
            max-width: 90ex;
        }
    }

    &--full {

        >p,
        >ul li {
            max-width: 100%;
        }
    }

    .usa-card {
        @include u-margin-bottom(2);

        &--light {
            @include u-bg('blue-5')
        }
    }
}

.usa-card {
    &__body {
        >.usa-alert {
            @include u-padding-top(1);

            &--slim {
                @include u-padding-top('05');
            }
        }

        >.usa-button {
            @include u-padding-top('105');
        }
    }

    ul.usa-list,
    ul.qhp-list {
        li {
            list-style-type: disc;
        }

        &--hover {
            li {
                list-style-type: none;
            }
        }
    }
}

.usa-footer {
    &__primary-link {
        @include u-text('normal')
    }
}

.usa-form {
    &--full {
        width: 100%;
        max-width: 100%;
    }

    .usa-text {
        margin-top: 6px;
        border-width: 2px;
        line-height: 32px;
    }

    .usa-textarea {
        width: 450px;
        height: 75px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    .usa-fieldset {
        @include u-margin-top(3);
    }

    .usa-legend {
        @include u-margin-top(0);
    }

    .usa-small {
        font-size: 80%;
        font-weight: normal;
    }
}

.usa-combo-box {
    &.margin-top-0 {
        input {
            @include u-margin-top(0);
        }
    }
}

input[type="text"]:read-only {
    @include u-bg('gray-10');
}

.usa-modal--wide {
    .usa-modal__main {
        max-width: none;
    }
}

.usa-tag {
    &--success {
        @include u-bg('success-darker')
    }

    &--error {
        @include u-bg('error-dark')
    }

    &--small {
        @include u-font('body', '3xs')
    }
}

.radius-circle {
    border-radius: 100%;
}

// React Data Table Component Overrides and Extensions
.rdt_TableHead {
    font-size: unset !important;
}

.rdt_TableCol {
    font-weight: bold;

    // This class name is generated by Styled Components, but it is the only way to override these styles.
    // Be aware that it might change in the future if the React Data Table Component is updated.
    // NOTE: A custom patch in the React Data Table Component makes this currently unnecessary.
    // .hbotQl {
    //     white-space: pre-line;
    //     word-wrap: break-word;
    // }
}

.rdt_TableCol_Sortable {
    align-items: start !important;

    &:hover,
    &:focus {
        opacity: 1 !important;

        span,
        span.__rdt_custom_sort_icon__ * {
            opacity: 1 !important;
            @include u-text('gray-50');
        }
    }
}

.rdt_TableRow {
    font-size: unset !important;

    button:not([disabled]):focus {
        outline-offset: -0.25rem;
    }
}

// Toastify toast message overrides and additions
.Toastify {
    $toastify: &;

    &__toast-container {
        width: 32rem;
    }

    &__toast-theme--colored {
        &#{$toastify}__toast--success {
            background-color: color('green-cool-50v');
        }

        &#{$toastify}__toast--error {
            background-color: color('red-60v');
        }
    }
}