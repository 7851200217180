// USWDS Settings
$theme-image-path: '~uswds/src/img';
$theme-font-path: '~uswds/src/fonts';
$theme-hero-image: '~/src/images/hero-01.png';
$theme-grid-container-max-width: "widescreen";
$theme-banner-max-width: "widescreen";
$theme-header-max-width: "widescreen";
$theme-footer-max-width: "widescreen";
$theme-font-role-heading: "sans";
$theme-h1-font-size: "xl";
$theme-h2-font-size: "lg";
$theme-card-header-typeset: "heading", "md", 2;

// QHP-Specific Colors
$qhp-color-primary: #0d2499;